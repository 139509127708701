<template>
  <EditEventStyled>
    <ModelEditor
      v-if="event"
      :title="title"
      :model="event"
      :errors="errors"
      :loading="$apollo.loading"
      :canSave="false"
      @save="handleSave"
      @cancel="handleCancel"
    >
      <EventStyled v-if="event">
        <VueJsonPretty
          v-if="event"
          :data="event"
          :selectableType="'multiple'"
          :showSelectController="false"
          :showLine="true"
          :collapsedOnClickBrackets="true"
          :highlightMouseoverNode="false"
          :highlightSelectedNode="false"
          :canSave="false"
          @click="handleClick"
        />
      </EventStyled>
    </ModelEditor>
  </EditEventStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'
import ModelEditor from '@/components/misc/ModelEditor'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

import EVENT_QUERY from '#/graphql/feed/myEvent.gql'

const EventStyled = styled('div')`
  grid-column: span 3;
  padding: 1rem;
  .vjs-tree.is-mouseover {
    background-color: ${({ theme }) => chroma(theme.colors.archonBlue).alpha(0.2).css()};
  }
`

const EditEventStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`
export default {
  components: {
    EventStyled,
    ModelEditor,
    VueJsonPretty,
    EditEventStyled,
  },
  data() {
    return {
      event: null,
      errors: [],
    }
  },
  computed: {
    title() {
      return `Event ${get(this.scheduledJob, 'name', '')}`
    },
  },
  methods: {
    handleClick() {},
    handleSave() {},
    handleCancel() {},
  },
  apollo: {
    scheduledJob: {
      query: EVENT_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      manual: true,
      result({ data }) {
        this.event = data?.myEvent
      },
    },
  },
}
</script>
