var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EditEventStyled', [_vm.event ? _c('ModelEditor', {
    attrs: {
      "title": _vm.title,
      "model": _vm.event,
      "errors": _vm.errors,
      "loading": _vm.$apollo.loading,
      "canSave": false
    },
    on: {
      "save": _vm.handleSave,
      "cancel": _vm.handleCancel
    }
  }, [_vm.event ? _c('EventStyled', [_vm.event ? _c('VueJsonPretty', {
    attrs: {
      "data": _vm.event,
      "selectableType": 'multiple',
      "showSelectController": false,
      "showLine": true,
      "collapsedOnClickBrackets": true,
      "highlightMouseoverNode": false,
      "highlightSelectedNode": false,
      "canSave": false
    },
    on: {
      "click": _vm.handleClick
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }